define("discourse/plugins/discourse-custom-post-display/discourse/initializers/initialize-discourse-custom-post-display", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse-common/lib/icon-library", "@ember/runloop", "discourse-common/lib/helpers"], function (_exports, _ajax, _pluginApi, _iconLibrary, _runloop, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    iconNode
  } = require("discourse-common/lib/icon-library");
  var _default = _exports.default = {
    name: "discourse-custom-post-display-plugin",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.25", api => {
        const siteSettings = api.container.lookup("site-settings:main");
        api.includePostAttributes('user_post_count', 'user_likes_received', 'user_join_date', 'user_badges');
        api.decorateWidget("poster-name:after", helper => {
          var badgeEls = [];
          if (typeof helper.attrs.user_badges !== 'undefined') {
            helper.attrs.user_badges.forEach(function (badge) {
              badgeEls.push(helper.h('span', {
                className: `cpd-badge cpd-badge-${badge.id} cpd-badge-${badge.slug}`
              }, helper.h('a', {
                href: `/badges/${badge.id}/${badge.slug}`,
                title: badge.name
              }, iconNode(badge.icon.replace("fa-", "")))));
            });
          }
          const els = [iconNode('far-calendar-alt', {
            'class': 'cpd-join-date-icon',
            title: 'Join Date'
          }), helper.h('span', {
            className: 'cpd-text cpd-join-label',
            title: 'Join Date'
          }, '' + 'Joined'), helper.h('span', {
            className: 'cpd-text cpd-join-date',
            title: 'Join Date'
          }, '' + helper.attrs.user_join_date), iconNode('edit', {
            'class': 'cpd-post-count-icon',
            title: 'Posts Written'
          }), helper.h('span', {
            className: 'cpd-text cpd-post-count',
            title: 'Posts Written'
          }, '' + helper.attrs.user_post_count), iconNode('far-thumbs-up', {
            'class': 'cpd-likes-received-icon',
            title: 'Likes Received'
          }), helper.h('span', {
            className: 'cpd-text cpd-likes-received',
            title: 'Likes Received'
          }, '' + helper.attrs.user_likes_received)];
          return helper.h("div.cpd-container", {}, helper.h('a', {
            href: siteSettings.custom_post_display_help_url,
            title: 'Join Date, Posts Written, Likes Received'
          }, helper.h('span.cpd-span', badgeEls.concat(els))));
        });
      });
    }
  };
});